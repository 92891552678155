
/*Styling for Title*/
.Akash{
    /*margin: 30% 0 5% 0;*/
    padding: 1rem 3rem;
    text-align: center;
    font-size: 65px;
    color: #F5F5F5;
}

/*Styling for row containing title*/
.Logo {
    display:flex;
    min-height: 100%;
    width:100%;
    align-items:center;
    justify-content: center;
    flex-direction:column;
    margin: 15% 0 4% 0;
}
