.Text-643 {
    font-size: 22px;
    text-align: justify-all;
    color: #F5F5F5;
}

.Info-wrap-643 {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    padding: 2rem;
}