/*Styling for About container*/
.About {
    width:100%;
    height: auto;
    margin:0 0 100px 0;
}

/*Styling for About me Title*/
.Title-about {
    text-align:center;
    color: #F5F5F5;
    margin: 5rem 0 2rem 0;
}

/*Styling for Persons image*/
.About-Akash {
    width: 100%;
    height: auto;
    margin: 7rem 3rem;
    padding: 5rem;
    /*CSS below is because the image was displayed horizontally*/
    transform: rotate(270deg);
    border-radius: 40px;
}

/*Styling for ABout text*/
.Text {
    font-size: 22px;
    padding: 1rem 0 3rem 0;
    color: #F5F5F5;
    display: inline-block;
    margin: 0 25% 0 0;
}

/*Styles Contact title*/
.Contact-title {
    color: #F5F5F5;
    margin: 0 25% 0 0;
}

/*Styles contact info text*/
.Info-text {
    font-size: 22px;
    padding: 1rem 0 ;
    text-align: left;
    color: #F5F5F5;
    display: inline-block;
    margin: 0 25% 0 0;
}

/*Styles link in the info-text section*/
.Info-text a {
    transition: 0.3s ease-in-out;
    text-decoration: none;
}

/*Styles social media wrappers*/
.Social-wrap {
    display: flex;
    margin: 0 25% 0 0;
    width: 100%;
    list-style: none;
}

/*Styles the link of each social media icon individually*/
.Social-list-about a{
    padding: 1rem 0.5rem;
    font-size: 32px;
    color: #F5F5F5;
}

/*Changes the colour of each social media icon on hover*/
.Social-list-about a:hover{
    color: #9a9a9a;
    transition: 0.3s ease-in-out;
}

/*Styles Return home button*/
.btn-wrapper-wrap-about {
    text-align:center;
    margin: 6rem 0;
}

/*Responsive styling*/
@media screen and (min-width: 700px) and (max-width: 1200px) {
    .Text, .Contact-title, .Info-text, .Social-wrap {
        margin: 0 5rem;
    }
}

@media screen and  (max-width: 700px) {

    .Text, .Contact-title, .Info-text, .Social-wrap {
        margin: 0 5rem;
    }

    .Text, .Contact-title, .Info-text {
        font-size:26px;
    }

    .About-Akash {
        height:100%;
        margin:0;
    }

}



