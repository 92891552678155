/*Styling for div wrap*/
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

/*Styling for modal content*/
.Modal-body{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    padding: 0;
    overflow: auto;
    max-width: 85%;
    max-height: 85%;
    height:auto;
    border-radius:4px;
    transform: translate(-50%, -50%);
}

/*Styling for images in the body*/
.Modal-image {
    width: 100%;
    height: auto;
}

/*positioning for close button*/
.close {
    top: 0;
    right:0;
}

/*Styling for image index*/
.Image-num {
    color: #F5F5F5;
    position: fixed;
    bottom: 0;
    right: 50%;
    display: flex;
    flex-direction: column;
    font-size:2.5rem;
}

/*Responsive styling*/
@media screen and (min-width: 700px) and (max-width: 1200px) {
    .Modal-image{
        width: 100%;
        height:auto;
    }
}

@media screen and  (max-width: 700px) {
    .Modal-image{
        width: 100%;
        height:auto;
    }
    .Image-num {
        right: 45%;
    }
}
