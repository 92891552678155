/*Styles Toolbar and makes it sticky*/
.Toolbar {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    background: #191b1e;
    height: 56px;
    margin-bottom: 2rem;
}

/*Styles Wrapper for navigation items*/
.Toolbar-nav {
    display: flex;
    height:100%;
    align-items: center;
}

/*Style navigation list for toolbar*/
.Toolbar-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

/*Styles each item in the navigation section*/
.Toolbar-nav-items {
    padding: 0 1rem;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*creates space between logo and navigation*/
.spacer {
    flex: 1;
}

/*Styles link portion of logo*/
.Toolbar-logo a{
    margin-left: 2rem;
    color: #F5F5F5;
    text-decoration: none;
    font-size: 3rem;
    font-family: 'Orbitron', sans-serif;
}

/*Styles link portion of navigation list*/
.Toolbar-nav-items a {
    color: #f5f5f5;
    text-decoration:none;
    font-size: 2rem;
}

/*Creates animation on hovering and clicking of navigation list*/
.Toolbar-nav-items:hover,
.Toolbar-nav-items:active {
    background: #F5F5F5;
    transition: 0.3s;
}

/*Creates animation on hovering and clicking of navigation list link*/
.Toolbar-nav-items:hover a,
.Toolbar-nav-items:active {
    color: #2a2c2f;
    transition: 0.3s;
}

/*Responsive media*/
@media screen and (min-width: 378px) and (max-width: 768px) {

    .Toolbar-nav-items a {
        font-size: 1.1rem;
    }

    .Toolbar-logo a{
        margin-left: 1rem;
        font-size: 1.7rem;
    }

    .Toolbar-nav-items {
        padding: 0 0.5rem;
    }
}

@media screen and (max-width: 378px) {

    .Toolbar-nav-items a {
        font-size:10px;
    }

    .Toolbar-logo a{
        margin-left: 0;
        font-size: 12px;
    }

    .Toolbar-nav-items {
        padding: 0 0.5rem;
    }
}