/*Styling for drawer button*/
.Toggle-btn {
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    height: 24px;
    width:36px;
    background: transparent;
    border: none;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding:0;
    box-sizing:border-box;
}

/*Prevents outline when drawer button is clicked*/
.Toggle-btn:focus {
    outline:none;
}

/*Styling for each specific line*/
.Toggle-btn-line {
    width:30px;
    height:2px;
    background: #F5F5F5;
}

/*Responsive design*/
@media screen and (min-width: 378px) and (max-width: 768px) {
    .Toggle-btn {
        height: 20px;
        width:32px;
        margin-top: 0;
    }
}

@media screen and (max-width: 378px) {

    .Toggle-btn {
        height: 20px;
        width:32px;
        margin-top: 0;
    }

    .Toggle-btn-line {
        width:26px;
    }
}
