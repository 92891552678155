/*Sets height of component to 100% of body*/
.Options{
    height:100%;
}

/*sets custom size for component container*/
.Categories-wrap{
    width: 55%;
    height: auto;
}

/*Styling for each individual image in the container*/
.Categories img{
    width: 100%;
    height: auto;
    padding-bottom:5%;
    display:flex;
    flex-direction: row;
}

/*Styles label for each image and sets it invisible*/
.label{
    text-align: center;
    font-size: 40px;
    margin: 0 10% 0 5%;
    Display: none;
}

/*Shows label only when the image is hovered on*/
a:hover + label{
    display:block;
}

/*Custom margin for project label*/
#project{
    margin:auto;
}

/*code to cover Vanta.js animation to the entire web page*/
.BG-animation {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

/*Responsive design*/
@media screen and (min-width: 768px) and (max-width: 991px) {
    .Categories-wrap {
        width:auto;
    }
}

@media screen and (min-width: 378px) and (max-width: 768px) {

    .Categories-wrap {
        width:100%;
    }

    .Categories {
        display: flex;
        flex-direction: row;
    }

    .label {
        font-size: 12px;
        margin: 0;
        Display: block;
    }

    #project{
        text-align: center;
        font-size: 12px;
    }

}

@media screen and (max-width: 378px) {

    .Categories-wrap {
        width:100%;
    }

    .Categories {
        display: flex;
        flex-direction: row;
    }

    .label {
        font-size: 12px;
        margin: 0;
        Display: block;
    }

    #project{
        text-align: center;
        font-size: 12px;
    }

    .Categories-col img.Other{
        width: 75%;
    }
}