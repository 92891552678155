/*CSS code for Foot.jsx*/

/*CSS for div wrapper*/
.Foot
{
    background-color: #1d1e1d;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
}

/*Styling for each individual link*/
.link
{
    text-decoration: none;
    color: #f8f8f8;
    font-size: 18px;
    padding:0 1rem;
}

/*Styling for each individual link when hovered on*/
.link:hover
{
    text-decoration: none;
    color: #aaaaaa;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

/*Styling for navigation*/
.Foot-list
{
    padding: 5% 0 5% 10%;
    margin: 0 auto;
    text-align:center;
    list-style: none;
}

/*Styling for list to ensure it is horizontal*/
.horizontal
{
    display: flex;
    justify-content: center;
    padding: 2rem 2rem;
}

/*Styling for copyright*/
.footer-copyright {
    text-align: center;
    font-size: 22px;
    margin: 0 auto;
    padding: 0 2% 0 0;
    color: #aaaaaa;
}

/*responsive styling*/
@media screen and (min-width: 400px) and (max-width: 500px) {
    .link
    {
        text-decoration: none;
        color: #f8f8f8;
        font-size: 12px;
        padding:0 0.5rem;
    }

    .footer-copyright {
        font-size:16px;
    }
}

@media screen and (max-width: 400px) {
    .link
    {
        font-size: 10px;
        padding:0 0.35rem;
    }

    .footer-copyright {
        font-size:14px;
    }
}
