/*Styling for close button*/
.Close-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 36px;
    background: transparent;
    border: none;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    top: 8px;
    right: 16px;
}

/*Prevent outline when close button is clicked*/
.Close-btn:focus {
    outline:none;
}

/*Styling for each line that makes the close button*/
.Close-btn-line{
    opacity: 0.5;
    width: 30px;
    height:2px;
    background: #F5F5F5;
}

/*Rotation for the first line used in the close button*/
.btn1 {
    transform: translateY(12px) rotate(45deg);
}

/*Rotation for the second line used in the close button*/
.btn2 {
    transform: rotate(-45deg);
}